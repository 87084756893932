/**
 * This is just a Class to load all the JS Modules used across the project
 */

const CwLoadModules = {
    async modals() {
        const { default: CWModals } = await import(
            /* webpackPrefetch: true, webpackChunkName: 'modals' */
            '@libraries/cw-modals.js'
        );
        return CWModals;
    },
    async AjaxCalls() {
        const { default: cwAjaxLib } = await import(
            /* webpackPrefetch: true, webpackChunkName: 'ajaxLoadMore' */
            '@libraries/loadMore.js'
        );
        return cwAjaxLib;
    },
    async AOSanimations() {
        const { default: AOS } = await import(
            /* webpackPrefetch: true, webpackChunkName: 'AOS' */
            'aos'
        );
        return AOS;
    },

    async mmenu() {
        const { default: MmenuLight } = await import(
            /* webpackPrefetch: true, webpackChunkName: 'mmenujs' */
            'mmenu-light'
        );

        return MmenuLight;
    },

    async Collapse() {
        const { default: collapse } = await import(
            /* webpackPrefetch: true, webpackChunkName: 'bootstrap-collapse' */
            'bootstrap/js/dist/collapse'
        );

        return collapse;
    },

    async Tabs() {
        const { default: tabs } = await import(
            /* webpackPrefetch: true, webpackChunkName: 'bootstrap-tabs' */
            'bootstrap/js/dist/tab'
        );

        return tabs;
    },

    async Dropdown() {
        const { default: dropdown } = await import(
            /* webpackPrefetch: true, webpackChunkName: 'bootstrap-dropdown' */
            'bootstrap/js/dist/dropdown'
        );

        return dropdown;
    },

    async swiperSliders(carouselModule) {
        const sliderCarousel = await import(
            /*  webpackChunkName: '[request]' */
            `@modules/${carouselModule}`
        );

        return sliderCarousel;
    },

    async swiperSlidersVendor() {
        const { default: Swiper } = await import(
            /* webpackPrefetch: true, webpackChunkName: 'swiper' */
            'swiper'
        );
        return Swiper;
    },

    async matchHeight() {
        const { default: MatchHeightjs } = await import(
            /* webpackPrefetch: true, webpackChunkName: 'js-match-height' */
            'js-match-height'
        );

        return MatchHeightjs;
    },
};

export default CwLoadModules;